import React from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';

import Arrow from '../../icons/Arrow';

interface IProps {
  title: string;
  icon: React.FC<{ fill: string }>;
  color: (opacity: number) => string;
  description: string;
}

const Styled = styled.div`
  background: ${colors.WHITE()};
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  padding: 2rem;
  overflow: hidden;
  transition: 250ms all;
  will-change: transform;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  }

  .border {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 6px;
  }
  .icon {
    margin-bottom: 1rem;
  }
  .icon svg {
    width: 2rem;
    height: 2rem;
  }
  p {
    margin: 0.5rem 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .learn-more svg {
    width: 1.5rem;
    transition: 250ms all;
  }
  &:hover .learn-more svg {
    transform: translateX(10px);
  }
  @media screen and (max-width: 768px) {
    .learn-more {
      opacity: 1;
    }
  }
`;

const Applet: React.FC<IProps> = props => (
  <Styled>
    <div className="border" style={{ background: props.color(1) }} />
    <div className="icon">{<props.icon fill={props.color(1)} />}</div>
    <h5 style={{ color: props.color(1) }}>{props.title}</h5>
    <p className="text-no-margin">{props.description}</p>
    <a className="learn-more" href="#" target="_blank">
      <Arrow fill={props.color(1)} />
    </a>
  </Styled>
);

export default Applet;
