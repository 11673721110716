import React from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';

interface IProps {
  style?: React.CSSProperties;
  className?: string;
}

const Styled = styled.div`
  border: 2px solid ${colors.WHITE(0.4)};
  border-top-color: ${colors.WHITE(0.1)};
  border-radius: 100%;
  height: 16px;
  width: 16px;
  animation: rotate 0.8s infinite linear;
  border-color: ${colors.PURPLE_LIGHT(0.6)};
  border-top-color: ${colors.PURPLE_LIGHT(0.9)};

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const Loader: React.FC<IProps> = props => (
  <Styled className={props.className} style={props.style} />
);

export default Loader;
