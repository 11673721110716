import { Link } from 'gatsby';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { colors, styles } from 'midtype-backend';

import Logo from './Logo';
import TryMidtype from './TryMidtype2';
import Button from './Button';

import { Container } from '../layout/Layout2';
import Bars from '../icons/Bars';
import Cross from '../icons/Cross';

const Styled = styled.header`
  position: fixed;
  width: 100vw;
  height: 4rem;
  top: 0;
  left: 0;
  z-index: ${styles.ZINDEX_MASK + 5};
  transition: 250ms background;

  &.scrolled {
    background: ${colors.WHITE(0.95)};
  }

  .container {
    display: flex;
    justify-content: space-between;
  }
  .container__left {
    display: flex;
  }
  .container__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .header__overflow {
    display: none;
    cursor: pointer;
  }
  .header__logo {
    margin-right: 2rem;
  }
  .header__logo svg tspan {
    font-weight: normal;
  }
  .header__links--container {
    justify-content: center;
  }
  .header__links {
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    margin-bottom: -2px;
  }
  .header__links__link {
    opacity: 0.8;
    transition: 250ms all;
    margin-right: 2rem;
  }
  .header__links__link:hover {
    opacity: 1;
  }
  .header__links__link.active {
    opacity: 1;
  }
  .header__links__link h5 {
    font-size: 1rem;
  }
  .header__links__link.active h5 {
    color: ${colors.PURPLE_DARK()};
  }
  .header__links__exit {
    position: absolute;
    right: 2rem;
    top: 2rem;
    display: none;
  }
  .header__links__exit svg {
    width: 2rem;
    height: 2rem;
    fill: ${colors.GRAY_3()};
  }
  .header__cta {
    justify-content: flex-end;
  }
  .header__cta h5 {
    font-size: 1.2rem;
  }
  .header__cta button {
    height: auto;
    padding: 0.5rem;
  }
  &.dark {
    background: none;
    border-bottom-color: ${colors.WHITE(0.1)};
    position: absolute;
    .header__links__link h5 {
      color: ${colors.WHITE(0.6)};
    }
    .header__links__link.active h5 {
      color: ${colors.WHITE()};
    }
    .header__cta button {
      background: none;
      border: 1px solid ${colors.WHITE()};
    }
    .header__cta h5 {
      color: ${colors.WHITE()};
    }
    .header__cta svg {
      fill: ${colors.WHITE()};
    }
    .header__logo svg tspan {
      fill: ${colors.WHITE()};
    }
    .header__logo .circle__holes {
      fill: none;
    }
    .header__logo .circle__shape {
      fill: ${colors.WHITE()};
    }
    .header__overflow svg {
      fill: ${colors.WHITE()};
    }
  }
  @media screen and (max-width: 768px) {
    .header__overflow {
      display: flex;
      margin-right: 1rem;
    }
    .header__logo svg {
      width: 75px;
    }
    .header__links--container {
      z-index: ${styles.ZINDEX_MASK_CONTENT};
      position: fixed;
      background: ${colors.WHITE(0.95)};
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      visibility: hidden;
      opacity: 0;
      transform: scale(1.2);
      transition: 250ms all;
      &.open {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }
    }
    .header__links {
      flex-flow: column;
    }
    .header__links__exit {
      display: block;
    }
    .header__links a h5 {
      font-size: 3rem;
      margin: 1rem 0;
    }
    &.dark .header__links__link h5 {
      color: ${colors.GRAY_3()};
    }
    &.dark .header__links__link.active h5 {
      color: ${colors.PURPLE_DARK()};
    }
  }
`;

interface IHeaderLinkProps {
  text: string;
  link: string;
}

const HeaderLink: React.FC<IHeaderLinkProps> = props => (
  <Link
    to={props.link}
    activeClassName="active"
    className="header__links__link"
    partiallyActive={true}
  >
    <h5 className="text-gray">{props.text}</h5>
  </Link>
);

const SCROLL_POINT = 50;

const Header: React.FC<any> = props => {
  const [navOpen, setNavOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      const y = window.scrollY;
      if (y > SCROLL_POINT && !scrolled) {
        setScrolled(true);
      } else if (y < SCROLL_POINT && scrolled) {
        setScrolled(false);
      }
    };
  }, [scrolled, setScrolled]);

  return (
    <Styled
      className={[
        props.dark ? 'dark' : 'light',
        scrolled ? 'scrolled' : 'not-scrolled'
      ].join(' ')}
    >
      <Container className="container">
        <div className="container__left">
          <div
            className="header__overflow flex"
            onClick={() => setNavOpen(true)}
          >
            <Bars fill={colors.PURPLE_DARK()} />
          </div>
          <div className="header__logo flex">
            <Link to="/">
              <Logo width={110} />
            </Link>
          </div>
          <div
            className={`header__links--container flex ${
              navOpen ? 'open' : 'closed'
            }`}
          >
            <div
              className="header__links__exit"
              onClick={() => setNavOpen(false)}
            >
              <Cross />
            </div>
            <div className="header__links flex">
              {/* <HeaderLink text="About" link="/about" /> */}
              <HeaderLink text="Products" link="/templates" />
              <HeaderLink text="Pricing" link="/pricing" />
              <a
                href="https://www.notion.so/midtype/Midtype-FAQs-3bd42e9afd1843649295b6f291df4f22"
                target="_blank"
                className="header__links__link"
              >
                <h5 className="text-gray">FAQs</h5>
              </a>
              <HeaderLink text="Customers" link="/customers" />
              <HeaderLink text="Blog" link="/blog" />
            </div>
          </div>
        </div>
        <div className="container__right">
          <div className="header__cta flex">
            <Button>
              <TryMidtype white={true} />
            </Button>
          </div>
        </div>
      </Container>
    </Styled>
  );
};

export default Header;
