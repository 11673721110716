import React, { useEffect } from 'react';
import styled from 'styled-components';
import Loadable from '@loadable/component';
import { Link } from 'gatsby';
import { colors, analytics } from 'midtype-backend';

import Page, { Container } from '../layout/Layout2';
import TryMidtype from '../components/TryMidtype2';
import LargeCTA from '../components/LargeCTA2';
import Button from '../components/Button';
import Image from '../components/Image';
import Applet from '../components/homepage/Applet';
import CodeDemos from '../components/homepage/CodeDemos2';
import CustomerJourney from '../components/homepage/CustomerJourney';

import Editing from '../icons/IllustrationEditing';

import Ticket from '../icons/TicketAlt';
import Newspaper from '../icons/Newspaper';
import QuestionCircle from '../icons/QuestionCircle';
import Bullhorn from '../icons/Bullhorn';

const Working = Loadable(() => import('../icons/AnimatedWorking'));

const Styled = styled.div`
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .container__image {
    flex: 1 1 auto;
    animation: 1s fadein;
    will-change: opacity;
  }
  .container__text {
    flex: 0 0 50%;
  }
  .section--opener {
    margin-top: -4rem;
    padding-top: 8rem;
    padding-bottom: 5rem;
  }
  .section--opener .container__text {
    flex: 0 0 40%;
    z-index: 1;
    position: relative;
  }
  .section--opener .container__image {
    position: absolute;
    right: -25%;
    bottom: -15%;
    width: 100%;
    opacity: 0.8;
  }
  .section--opener .container__image svg {
    animation: 1s fadein;
  }
  .section--customizable {
    padding-bottom: 0;
    overflow: visible;
  }
  .section--customizable .container__text svg {
    width: 20rem;
    height: 20rem;
    z-index: -1;
    position: relative;
  }
  .section--customizable .container__image {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin-right: 6rem;
  }
  .trusted-by {
    margin-top: 4rem;
  }
  .trusted-by__logos {
    margin-top: 2rem;
    display: flex;
    align-items: center;
  }
  .trusted-by__logos__logo {
    filter: grayscale(100%);
    opacity: 0.4;
    width: 3rem;
    margin-right: 2rem;
    transition: 250ms all;
  }
  .trusted-by__logos__logo--pause {
    width: 5rem;
  }
  .trusted-by__logos__logo--yc {
    width: 9rem;
  }
  .trusted-by__logos__logo:hover {
    filter: grayscale(0%);
    opacity: 0.7;
  }
  .section--devs {
    margin-top: -3.5rem;
    padding-top: 9rem;
    padding-bottom: 0;
  }
  .section--devs .container__text {
    margin-bottom: 3rem;
    width: 100%;
    text-align: center;
  }
  .section--devs .container__text p {
    max-width: 40rem;
    margin: auto;
  }

  .section--customers {
    padding-bottom: 2rem;
  }
  .section--customers .container__text {
    margin-right: 6rem;
    text-align: right;
    flex: 0 0 40%;
  }

  @media screen and (max-width: 800px) {
    .container {
      flex-flow: column;
    }
    .container__text {
      width: 100%;
      text-align: center;
    }
    .section--opener .container__text {
      text-align: left;
      width: 80%;
    }
    .section--opener .container__image {
      bottom: -25%;
    }
    .trusted-by__logos {
      flex-flow: row wrap;
      max-width: 50%;
    }
    .trusted-by__logos__logo {
      margin-bottom: 1rem;
    }
    .section--customizable .container__image {
      order: 1;
      margin: 0;
    }
    .section--customizable .container__text {
      order: 0;
      margin: 0;
      margin-bottom: 2rem;
    }
    .section--devs .container__text {
      margin-bottom: 1rem;
    }
    .section--customers .container__text {
      margin-right: 0;
      text-align: center;
    }
  }
  @media screen and (max-width: 500px) {
    .section--opener .container__text {
      width: 100%;
    }
    .section--opener .container__image {
      bottom: -10%;
    }
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Section = styled.article`
  width: 100vw;
  padding: 6rem 0;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    padding: 4rem 0;
  }
`;

const IndexPage: React.FC = () => {
  useEffect(() => {
    analytics.track('Viewed Customer Support Landing Page');
  }, []);
  return (
    <Page
      pageTitle="Craft your ideal customer feedback loop."
      pageDescription="Control the look, feel, and functionality of how you provide customer support with Midtype."
    >
      <Styled>
        <Section className="section--opener" style={{ background: '#F7F7F7' }}>
          <Container className="container">
            <div className="container__text">
              <h1 style={{ margin: 'auto', marginBottom: 20 }}>
                Craft your <span className="text-accent">ideal</span>
                &nbsp;customer feedback&nbsp;loop.
              </h1>
              <p className="large">
                Building blocks for integrating customer feedback channels
                throughout your app.
              </p>
              <Button style={{ marginTop: '2rem' }}>
                <TryMidtype white={true} />
              </Button>
              <div className="trusted-by">
                <div className="trusted-by__logos">
                  <div className="trusted-by__logos__logo trusted-by__logos__logo--pause">
                    <Image src="logos/pause.png" />
                  </div>
                  <Link to="/customers/how-clokka-released-marketplace-app-in-3-weeks/">
                    <div className="trusted-by__logos__logo">
                      <Image src="logos/clokka2.png" />
                    </div>
                  </Link>
                  <a href="https://www.techcrunch.com" target="_blank">
                    <div className="trusted-by__logos__logo">
                      <Image src="logos/techcrunch.png" />
                    </div>
                  </a>
                  <a
                    href="https://www.ycombinator.com/companies"
                    target="_blank"
                  >
                    <div className="trusted-by__logos__logo trusted-by__logos__logo--yc">
                      <Image src="logos/ycombinator.png" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="container__image">
              <Working size="100%" />
            </div>
          </Container>
        </Section>

        <Section className="section--customizable">
          <Container className="container">
            <div className="container__image">
              <Applet
                title="Support"
                icon={Ticket}
                description="Track and assign support tickets to your team."
                color={colors.BLUE_LIGHT}
              />
              <Applet
                title="Questions"
                icon={QuestionCircle}
                description="Allow customers to submit questions for your team to answer."
                color={colors.PURPLE_LIGHT}
              />
              <Applet
                title="Feedback"
                icon={Bullhorn}
                description="Collect feature requests and prioritize them on a roadmap."
                color={colors.ORANGE}
              />
              <Applet
                title="Articles"
                icon={Newspaper}
                description="Write FAQs and guides for your product and allow users to upvote the most useful ones."
                color={colors.BLUE_DARK}
              />
            </div>
            <div className="container__text">
              <h3 style={{ marginBottom: 20 }}>
                Fully Customizable Customer&nbsp;Feedback
              </h3>
              <p>
                <strong>
                  You designed your product with love. Your feedback channels
                  deserve the same.
                </strong>{' '}
                Only Midtype supplies a suite of ready-to-go apps for collecting
                feedback from your customers. You have complete control over the
                look, feel, and functionality. Use them as is, or integrate them
                into your product.
              </p>
              <Editing />
            </div>
          </Container>
        </Section>

        <Section className="section--devs" style={{ background: '#F7F7F7' }}>
          <Container className="container" style={{ flexFlow: 'column' }}>
            <div className="container__text">
              <h3 style={{ marginBottom: 10 }}>Developers First</h3>
              <p>
                The APIs used by Midtype's apps are also available to your
                engineering team, with detailed documentation on how to use
                them. Easily extend the API by defining custom data schemas that
                meet your requirements.
              </p>
            </div>
            <CodeDemos />
          </Container>
        </Section>

        <Section className="section--customers">
          <Container className="container">
            <div className="container__text">
              <h3 style={{ marginBottom: 20 }}>
                Understand Your&nbsp;Customers
              </h3>
              <p>
                Integrate your user authentication system with Midtype so that
                customers don’t have to login to another service to create
                support tickets or start a conversation. Get a holistic view of
                customer activity and support needs by connecting your product
                analytics and user database.
              </p>
            </div>
            <div className="container__image">
              <CustomerJourney />
            </div>
          </Container>
        </Section>

        <LargeCTA />
      </Styled>
    </Page>
  );
};

export default IndexPage;
