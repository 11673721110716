import React, { useCallback, CSSProperties } from 'react';
import styled from 'styled-components';
import { colors, analytics } from 'midtype-backend';

import Arrow from '../icons/Arrow';

interface IProps {
  email?: string;
  white?: boolean;
  style?: CSSProperties;
}

export const useTryMidtype = () =>
  useCallback(() => {
    analytics.track('Clicked Schedule A Demo');
    window.location.assign('https://calendly.com/nilkanth/midtype-call');
  }, []);

const Styled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 250ms opacity;
  h5 {
    color: ${(props: IProps) =>
      props.white ? colors.WHITE() : colors.PURPLE_DARK()};
    margin: 0 !important;
    margin-right: 5px !important;
    padding: 0 5px !important;
  }
  svg {
    transform: translateX(0);
    transition: 250ms transform;
    fill: ${(props: IProps) =>
      props.white ? colors.WHITE() : colors.PURPLE_DARK()};
  }
  &:hover {
    opacity: 1;
  }
  &:hover svg {
    transform: translateX(3px);
  }
  @media screen and (max-width: 500px) {
    h5 {
      font-size: 1.2rem;
    }
  }
`;

const GetStarted: React.FC<IProps> = props => {
  const onClick = useTryMidtype();
  return (
    <Styled
      style={props.style}
      className="try-midtype"
      onClick={onClick}
      {...props}
    >
      <h5>Schedule a Demo</h5>
      <Arrow />
    </Styled>
  );
};

export default GetStarted;
