import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';

import Image from '../Image';

import Heartbeat from '../../icons/Heartbeat';
import Stripe from '../../icons/Stripe';
import Keyboard from '../../icons/Keyboard';

const Styled = styled.div`
  background: #f7f7f7;
  border-radius: 1rem;
  width: 100%;
  height: 20rem;
  position: relative;

  .customer {
    background: ${colors.WHITE()};
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: absolute;
    padding: 1rem;
    overflow: hidden;
    transition: 250ms all;
    width: fit-content;
    overflow: hidden;

    top: -3rem;
    left: -3rem;
  }
  .customer__border {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background: ${colors.PURPLE_LIGHT()};
  }
  .customer__info {
    display: flex;
  }
  .customer__info__avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }
  .customer__info__name {
    font-size: 1rem;
    margin: 0;
    line-height: 1.2rem;
  }
  .journey {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
  }
  .journey__line {
    position: absolute;
    width: 0.5rem;
    left: calc(50% - 0.25rem);
    height: 100%;
    background: ${colors.PURPLE_LIGHT(0.2)};
  }
  .journey__event {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2.5rem 1fr;
    grid-gap: 1rem;
  }
  .journey__event--support {
    margin-top: 2rem;
  }
  .journey__event--stripe {
    margin-top: 5rem;
    .journey__event__point:before {
      background: ${colors.PURPLE_DARK(0.4)};
    }
    .journey__event__point svg {
      fill: ${colors.WHITE()};
    }
    .journey__event__point:after {
      background: ${colors.PURPLE_DARK(0.5)};
    }
    .journey__event__title {
      color: ${colors.PURPLE_DARK()};
    }
  }
  .journey__event--activity {
    margin-top: 2rem;
    .journey__event__point:before {
      background: ${colors.BLUE_LIGHT(0.4)};
    }
    .journey__event__point svg {
      fill: ${colors.BLUE_LIGHT()};
    }
    .journey__event__point:after {
      background: ${colors.BLUE_LIGHT(0.5)};
    }
    .journey__event__title {
      color: ${colors.BLUE_LIGHT()};
    }
  }
  .journey__event__point {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    grid-area: point;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    grid-area: 1 / 2 / 2 / 3;
    background: ${colors.WHITE()};
  }
  .journey__event__point:before {
    content: '';
    display: flex;
    background: ${colors.PURPLE_LIGHT(0.4)};
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
  }
  .journey__event__point:after {
    content: '';
    display: flex;
    background: ${colors.PURPLE_LIGHT(0.5)};
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    transform: scale(1.5);
    animation: pulse 3s infinite;
  }
  .journey__event__point svg {
    width: 20px;
    height: 20px;
    fill: ${colors.PURPLE_LIGHT()};
    z-index: 1;
  }
  .journey__event--stripe .journey__event__point svg {
    width: 35px;
    height: 35px;
  }
  .journey__event__title {
    grid-area: 1 / 3 / 2 / 3;
    color: ${colors.PURPLE_LIGHT()};
    font-size: 0.8rem;
    margin: 0;
    line-height: 1rem;
  }
  .journey__event__title--left {
    text-align: right;
    grid-area: 1 / 1 / 2 / 2;
  }
  @media screen and (max-width: 800px) {
    margin-top: 4rem;
    height: 15rem;
    .customer {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      top: -3rem;
      left: -1rem;
    }
    .journey__event--support {
      margin-top: 1rem;
    }
    .journey__event--stripe {
      margin-top: 3rem;
    }
    .journey__event--activity {
      margin-top: 1rem;
    }
  }
  @keyframes pulse {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
      transform: scale(2);
    }
  }
`;

const CustomerJourney: React.FC = () => {
  return (
    <Styled>
      <div className="customer">
        <div className="customer__border"></div>
        <div className="customer__info">
          <div className="customer__info__avatar">
            <Image src="headshots/neel.jpg" />
          </div>
          <p className="customer__info__name">
            <strong>Nilkanth Patel</strong>
            <br />
            Last seen 23 minutes ago.
          </p>
        </div>
      </div>
      <div className="journey">
        <div className="journey__line" />
        <div className="journey__event journey__event--support">
          <div className="journey__event__point">
            <Keyboard />
          </div>
          <p className="journey__event__title">
            <strong>Chatted with James Y.</strong>
            <br />6 hours ago
          </p>
        </div>
        <div className="journey__event journey__event--stripe">
          <div className="journey__event__point">
            <Stripe />
          </div>
          <p className="journey__event__title journey__event__title--left">
            <strong>Subscribed to Paid Plan</strong>
            <br />3 days ago
          </p>
        </div>
        <div className="journey__event journey__event--activity">
          <div className="journey__event__point">
            <Heartbeat />
          </div>
          <p className="journey__event__title">
            <strong>Visited Home Page</strong>
            <br />5 days ago
          </p>
        </div>
      </div>
    </Styled>
  );
};

export default CustomerJourney;
