import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors, styles } from 'midtype-backend';

import LoaderSmall from './LoaderSmall';

interface IProps {
  status?: string;
  secondary?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  loading?: boolean;
}

const Styled = styled.button`
  border: 0;
  box-shadow: none;
  outline: none;
  background: none;
  height: 3rem;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  transition: 250ms all;
  transform: translateZ(0);
  text-decoration: none;
  background-image: ${(props: IProps) => {
    if (props.secondary) {
      return 'none';
    }
    return `linear-gradient(90deg,
      ${colors.PURPLE_LIGHT(0.8)} 0%,
      ${colors.PURPLE_LIGHT(1)} 100%
    )`;
  }};
  border: none;
  &:hover {
    box-shadow: ${styles.BOX_SHADOW_PURPLE};
    transform: translateY(-2px);
  }
  h5 {
    transition: 250ms opacity;
    color: ${(props: IProps) => {
      if (props.secondary) {
        return colors.PURPLE_LIGHT();
      }
      return colors.WHITE();
    }};
  }
  &.loading h5 {
    opacity: 0;
  }
  .button__loading {
    position: absolute;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
    border: 2px solid ${colors.WHITE(0.4)};
    border-top-color: ${colors.WHITE(0.1)};
    border-radius: 100%;
    height: 16px;
    width: 16px;
    animation: rotate 0.8s infinite linear;
    border-color: ${(props: IProps) => {
      if (props.status === 'error' && props.secondary) {
        return colors.RED(0.6);
      } else if (props.secondary) {
        return colors.PURPLE_LIGHT(0.6);
      }
      return colors.WHITE(0.4);
    }};
    border-top-color: ${(props: IProps) => {
      if (props.status === 'error') {
        return colors.RED(0.9);
      } else if (props.secondary) {
        return colors.PURPLE_LIGHT(0.9);
      }
      return colors.WHITE(0.2);
    }};
  }
`;

const Button: React.FC<IProps> = props => (
  <Styled
    status={props.status}
    secondary={props.secondary}
    onClick={props.onClick}
    style={props.style}
    className={props.loading ? 'loading' : ''}
  >
    <h5>{props.children}</h5>
    {props.loading && <LoaderSmall className="button__loading" />}
  </Styled>
);

export default Button;
