import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';

import Logo from './Logo';
import { Container } from '../layout/Layout2';

const Styled = styled.footer`
  width: 100vw;
  padding: 3rem 0;
  background: ${colors.BLUE_DARK()};
  .footer__links {
    max-width: 60rem;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
  }
  .footer__links__col {
    flex-flow: column;
    align-items: flex-start;
  }
  .footer__links__link {
    text-decoration: none;
    margin: 10px 0;
    opacity: 0.6;
    transition: 250ms all;
    font-size: 1rem;
    color: ${colors.WHITE()};
  }
  .footer__links__link:hover {
    opacity: 1;
  }
  .footer__links__logo svg path {
    fill: ${colors.WHITE()};
  }
  .footer__links__logo svg circle {
    fill: none;
  }
  @media only screen and (max-width: 768px) {
    .footer__links {
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 40px 1fr;
    }
    .footer__links__logo {
      text-align: center;
      grid-column: 1 / span 3;
    }
    .footer__links__col {
      align-items: center;
    }
  }
`;

interface IFooterLinkProps {
  text: string;
  link: string;
}

const FooterLink: React.FC<IFooterLinkProps> = props => (
  <Link to={props.link} className="footer__links__link">
    {props.text}
  </Link>
);

const Footer: React.FC = () => (
  <Styled>
    <Container className="footer__links">
      <div className="footer__links__logo">
        <Logo width={110} />
      </div>
      <div className="footer__links__col flex">
        <FooterLink link="/about" text="About" />
        <FooterLink link="/contact" text="Contact Us" />
      </div>
      <div className="footer__links__col flex">
        <a
          href="https://www.notion.so/midtype/Midtype-FAQs-3bd42e9afd1843649295b6f291df4f22"
          target="_blank"
          className="footer__links__link"
        >
          FAQs
        </a>
        <FooterLink link="/docs" text="Documentation" />
        <FooterLink link="/blog" text="Blog" />
        <FooterLink link="/pricing" text="Pricing" />
      </div>
      <div className="footer__links__col flex">
        <FooterLink link="/privacy-policy" text="Privacy Policy" />
        <FooterLink link="/terms" text="Terms of Service" />
      </div>
    </Container>
  </Styled>
);

export default Footer;
