import React from 'react';
import styled from 'styled-components';
import { colors } from 'midtype-backend';

import Button from './Button';
import TryMidtype from './TryMidtype2';
import { Container } from '../layout/Layout2';

const Styled = styled.div`
  width: 100%;
  text-align: left;
  background-image: linear-gradient(
    -90deg,
    #6332a0 0%,
    ${colors.PURPLE_DARK()} 100%
  );
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3,
  p {
    color: ${colors.WHITE()};
  }

  .left {
    margin-right: 30px;
    max-width: calc(100% - 220px);
  }
  button {
    min-width: 220px;
  }
  @media screen and (max-width: 768px) {
    flex-flow: column;
    align-items: flex-start;
    padding: 2rem;
    .left {
      margin-right: 0px;
      margin-bottom: 30px;
      max-width: none;
    }
  }
  @media screen and (max-width: 400px) {
    margin-top: 0;
    border-radius: 0;
    width: 100vw;
    margin-left: -2rem;
  }
`;

const LargeCTA: React.FC = () => (
  <Container style={{ margin: '5rem auto' }}>
    <Styled>
      <div className="left">
        <h3>Ready to supercharge customer support?</h3>
        <p>
          Get started with a Midtype app in minutes, no credit card required.
        </p>
      </div>
      <Button>
        <TryMidtype white={true} />
      </Button>
    </Styled>
  </Container>
);

export default LargeCTA;
