import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { CodeUncontrolled, Snippet } from '../../CodeSample';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <CodeUncontrolled {...props}>{props.children}</CodeUncontrolled>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Snippet language="js" mdxType="Snippet">
      <pre {...{
        "className": "quiet-light vscode-highlight",
        "data-language": "javascript"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk10"
            }}>{`await`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Midtype`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`actions`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`create`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk9 mtkb"
            }}>{`comment`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  title`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`I love to comment!`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  text`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`The body of my comment has plenty more of my thoughts.`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  commenter`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Midtype`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`user`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`id`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span></code></pre>
    </Snippet>
    <Snippet language="node" mdxType="Snippet">
      <pre {...{
        "className": "quiet-light vscode-highlight",
        "data-language": "javascript"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk7"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`newComment`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`await`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`midtype`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`actions`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`create`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk9 mtkb"
            }}>{`comment`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  title`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`I love to comment!`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  text`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk4"
            }}>{`The body of my comment has plenty more of my thoughts.`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  commenter`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Midtype`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`user`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`id`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span></code></pre>
    </Snippet>
    <Snippet language="python" mdxType="Snippet">
      <pre {...{
        "className": "quiet-light vscode-highlight",
        "data-language": "md"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`Coming Soon!`}</span></span></code></pre>
    </Snippet>


    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`:root {
  --vscode-highlight-padding-v: 1rem;
  --vscode-highlight-padding-h: 1.5rem;
  --vscode-highlight-padding-top: var(--vscode-highlight-padding-v);
  --vscode-highlight-padding-right: var(--vscode-highlight-padding-h);
  --vscode-highlight-padding-bottom: var(--vscode-highlight-padding-v);
  --vscode-highlight-padding-left: var(--vscode-highlight-padding-h);
  --vscode-highlight-border-radius: 8px;

  --vscode-highlight-line-highlighted-background-color: transparent;
  --vscode-highlight-line-highlighted-border-width: 4px;
  --vscode-highlight-line-highlighted-border-color: transparent;
}

.vscode-highlight {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 1rem;
  padding-top: var(--vscode-highlight-padding-top);
  padding-bottom: 1rem;
  padding-bottom: var(--vscode-highlight-padding-bottom);
  border-radius: 8px;
  border-radius: var(--vscode-highlight-border-radius);
  font-feature-settings: normal;
}

.vscode-highlight-code {
  display: inline-block;
  min-width: 100%;
}

.vscode-highlight-line {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding-left: 1.5rem;
  padding-left: var(--vscode-highlight-padding-left);
  padding-right: 1.5rem;
  padding-right: var(--vscode-highlight-padding-right);
}

.vscode-highlight-line-highlighted {
  background-color: var(--vscode-highlight-line-highlighted-background-color);
  box-shadow: inset var(--vscode-highlight-line-highlighted-border-width) 0 0 0 var(--vscode-highlight-line-highlighted-border-color);
}
.quiet-light {
background-color: #F5F5F5;
}

.quiet-light .mtk1 { color: #333333; }
.quiet-light .mtk2 { color: #F5F5F5; }
.quiet-light .mtk3 { color: #AAAAAA; }
.quiet-light .mtk4 { color: #448C27; }
.quiet-light .mtk5 { color: #9C5D27; }
.quiet-light .mtk6 { color: #777777; }
.quiet-light .mtk7 { color: #7A3E9D; }
.quiet-light .mtk8 { color: #660000; }
.quiet-light .mtk9 { color: #AA3731; }
.quiet-light .mtk10 { color: #4B69C6; }
.quiet-light .mtk11 { color: #8190A0; }
.quiet-light .mtk12 { color: #96000014; }
.quiet-light .mtk13 { color: #000000; }
.quiet-light .mtk14 { color: #FFFFDD; }
.quiet-light .mtk15 { color: #FFDDDD; }
.quiet-light .mtk16 { color: #DDFFDD; }
.quiet-light .mtk17 { color: #434343; }
.quiet-light .mtk18 { color: #DDDDFF; }
.quiet-light .mtk19 { color: #333333FF; }
.quiet-light .mtk20 { color: #91B3E0; }
.quiet-light .mtk21 { color: #EAEBE6; }
.quiet-light .mtki { font-style: italic; }
.quiet-light .mtkb { font-weight: bold; }
.quiet-light .mtku { text-decoration: underline; text-underline-position: under; }`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      